import mixpanel from 'mixpanel-browser';

import { Provider } from './types';
import { flattenEvent } from './utils';

export default {
  id: 'mixpanel',
  emit: (event) => {
    const name = [event.flow, event.object, event.name, event.action]
      .filter(Boolean)
      .join(' ');

    mixpanel.track(
      name,
      flattenEvent(event, { pattern: /_/g, replacement: ' ' }),
    );
  },
} as Provider;
